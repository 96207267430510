.display-block {
	display: block;
}

.display-none {
	display: none;
}

.react-spinner {
    background-color: #081418;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    opacity: .5;
    z-index: 1;
}

.buttom-remove {
    margin: 0;
    padding: 0;
    margin-top: 30px;
}

.bg-form {
    background-color: #eef4f8; 
    color: #1f1e1e;
}

.font-p-minus {
    font-size: 0.85rem;
    font-weight: lighter;
}

.font-p {
    font-size: 1rem;
    font-weight: lighter;
}

.font-m {
    font-size: 1.3rem;
    font-weight: lighter;
}

.font-g {
    font-size: 1.7rem;
    font-weight: lighter;
}
