.toast-header-success {
    color: #ffffff;
    background-color: #52a54a;
    opacity: .85;
}

.toast-body-success {
    color: #ffffff;
    background-color: #52a54a;
    opacity: .75;
}

.toast-header-danger {
    color: #ffffff;
    background-color: #b93627;
    opacity: .85;
}

.toast-body-danger {
    color: #ffffff;
    background-color: #b93627;
    opacity: .8;
}

.toast-header-info {
    color: #ffffff;
    background-color: #3f96b7;
    opacity: .9;
}

.toast-body-info {
    color: #ffffff;
    background-color: #3f96b7;
    opacity: .85;
}

.toast-header-warning {
    color: #ffffff;
    background-color: #f29800;
    opacity: .88;
}

.toast-body-warning {
    color: #ffffff;
    background-color: #f29800;
    opacity: .8;
}

.toast-header-dark {
    color: #ffffff;
    background-color: #343a40;
    opacity: .8;
}

.toast-body-dark {
    color: #ffffff;
    background-color: #343a40;
    opacity: .75;
}
