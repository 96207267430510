.my-cursor {
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;
}

.my-cursor:hover {
    background-color: #2c85c9;
    color: #ffffff;
}
